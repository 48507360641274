import { Link } from "react-router-dom";

export default function Copyright() {
  const currentYear = 2025;
  const agencyName = "BRIX Agency";

  return (
    <div className="row pb-4">
      <div className="col-12 copyrightt">
        <p>
          Copyright © {currentYear} {agencyName} | All Rights Reserved |{" "}
          <Link to="/Coach/Terms" target="_blank" rel="noopener noreferrer">
            Terms and Conditions
          </Link>{" "}
          |{" "}
          <Link to="/Coach/Privacy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </Link>
        </p>
      </div>
    </div>
  );
}
