import React from "react";
import ManagerNavHome from "../HomeManagerComponents/ManagerNavHome";
import ManagerFooterHome from "../HomeManagerComponents/ManagerFooterHome";

function Games() {
  return (
    <>
      <ManagerNavHome />

      <section className="mygames head_space">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mb-5">
              <div className="row comman_topbar">
                <div className="col ps-0">
                  <div className="commantopbar_content">
                    <h1>Games</h1>
                    <p>
                      Lorem Ipsum&nbsp;is simply dummy text of the printing and
                      typesetting industry.&nbsp;
                    </p>
                  </div>
                </div>
                <div className="col-auto pe-0"></div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6 mb-4">
                  <div className="mygames_box">
                    <div className="mygames_img">
                      <img
                        src="../assets/img/team-manager/game_img.png"
                        alt=""
                      />
                    </div>
                    <div className="mygames_content mt-3">
                      <h2>Club Individual Tournaments</h2>
                      <p>
                        Lorem Ipsum&nbsp;is simply dummy text of the printing
                        and typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type{" "}
                      </p>
                      <a href="upcoming-games.html">View More</a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="mygames_box">
                    <div className="mygames_img">
                      <img
                        src="../assets/img/team-manager/game_img.png"
                        alt=""
                      />
                    </div>
                    <div className="mygames_content mt-3">
                      <h2>Annual Allumni Tournament </h2>
                      <p>
                        Lorem Ipsum&nbsp;is simply dummy text of the printing
                        and typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type{" "}
                      </p>
                      <a href="upcoming-games.html">View More</a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="mygames_box">
                    <div className="mygames_img">
                      <img
                        src="../assets/img/team-manager/game_img.png"
                        alt=""
                      />
                    </div>
                    <div className="mygames_content mt-3">
                      <h2>school chess championship</h2>
                      <p>
                        Lorem Ipsum&nbsp;is simply dummy text of the printing
                        and typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type{" "}
                      </p>
                      <a href="upcoming-games.html">View More</a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="mygames_box">
                    <div className="mygames_img">
                      <img
                        src="../assets/img/team-manager/game_img.png"
                        alt=""
                      />
                    </div>
                    <div className="mygames_content mt-3">
                      <h2>league team tounaments</h2>
                      <p>
                        Lorem Ipsum&nbsp;is simply dummy text of the printing
                        and typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type{" "}
                      </p>
                      <a href="upcoming-games.html">View More</a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="mygames_box">
                    <div className="mygames_img">
                      <img
                        src="../assets/img/team-manager/game_img.png"
                        alt=""
                      />
                    </div>
                    <div className="mygames_content mt-3">
                      <h2>City Team League</h2>
                      <p>
                        Lorem Ipsum&nbsp;is simply dummy text of the printing
                        and typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type{" "}
                      </p>
                      <a href="upcoming-games.html">View More</a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="mygames_box">
                    <div className="mygames_img">
                      <img
                        src="../assets/img/team-manager/game_img.png"
                        alt=""
                      />
                    </div>
                    <div className="mygames_content mt-3">
                      <h2>City Individual League</h2>
                      <p>
                        Lorem Ipsum&nbsp;is simply dummy text of the printing
                        and typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type{" "}
                      </p>
                      <a href="upcoming-games.html">View More</a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="mygames_box">
                    <div className="mygames_img">
                      <img
                        src="../assets/img/team-manager/game_img.png"
                        alt=""
                      />
                    </div>
                    <div className="mygames_content mt-3">
                      <h2>Regional Team League</h2>
                      <p>
                        Lorem Ipsum&nbsp;is simply dummy text of the printing
                        and typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type{" "}
                      </p>
                      <a href="upcoming-games.html">View More</a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="mygames_box">
                    <div className="mygames_img">
                      <img
                        src="../assets/img/team-manager/game_img.png"
                        alt=""
                      />
                    </div>
                    <div className="mygames_content mt-3">
                      <h2>Regional Individual League</h2>
                      <p>
                        Lorem Ipsum&nbsp;is simply dummy text of the printing
                        and typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type{" "}
                      </p>
                      <a href="upcoming-games.html">View More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ManagerFooterHome />
    </>
  );
}

export default Games;
