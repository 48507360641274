import React, { useEffect, useRef, useState } from "react";
import { useUserAuth } from "../../Authentications/Others/AuthContext";
import { Link, useLocation } from "react-router-dom";
import { GetNotifications } from "../../HttpServices/aluminiaHttpServices/alumniHttpServices";
import moment from "moment";
import Swal from "sweetalert2";
import { StudentRejectAccept } from "../../HttpServices/studentHttpServices/studentHttpServices";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import {
  coachChangeLanguage,
  readNotifications,
} from "../../HttpServices/coachHttpServices/coachHttpServices";

const CoachHomeNav = () => {
  const { logout, getMyProfile, userData } = useUserAuth();

  const sessionId = localStorage.getItem("sessionId");

  const { t, i18n } = useTranslation();

  const changeLanguage = async (lang) => {
    i18n.changeLanguage(lang !== "Hebrew" ? "en" : "he");

    const alldata = {
      language: lang,
      deviceId: sessionId,
    };

    const res = await coachChangeLanguage(alldata);
    if (res?.data?.error || res?.error?.data?.error) {
      toast.error(res?.data?.message || res?.error?.data?.message);
    } else {
      toast.success(res?.data?.message);
    }
    console.log("res", res);
  };

  useEffect(() => {
    getMyProfile();
  }, []);

  const [notiData, setNotiData] = useState([]);
  const [displayedNotiCount, setDisplayedNotiCount] = useState(4);
  const [totalNotifications, setTotalNotifications] = useState(0);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  const audioPlayer = useRef(null);
  const handleAcceptRejectStudent = async (id, status) => {
    try {
      const response = await StudentRejectAccept({ id, status });

      if (!response?.error) {
        Swal.fire({
          title: status ? "Accepted!" : "Rejected!",
          text: `The student has been ${
            status ? "accepted" : "rejected"
          } successfully.`,
          icon: "success",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.log(error?.message);

      Swal.fire({
        title: "Error!",
        text: `Something went wrong: ${error?.message}`,
        icon: "error",
        confirmButtonText: "Try Again",
      });
    }
  };

  const handleReadNotification = async () => {
    const res = await readNotifications();
    console.log("res", res);

    if (res?.data?.error || res?.error?.data?.error) {
      toast.error(res?.data?.message || res?.error?.data?.message);
    } else {
      grabNotifications();
      getMyProfile();
    }
  };
  function playAudio() {
    audioPlayer.current.play();
  }
  const grabNotifications = async () => {
    try {
      setLoading(true);
      const response = await GetNotifications({
        page: 1,
        pageSize: 5,
      });
      setNotiData(response?.data?.results?.notifications);
      setTotalNotifications(response?.data?.results?.notifications.length);
      setLoading(false);
    } catch (error) {
      console.log(error?.message);
      setLoading(false);
    }
  };

  const loadMoreNotifications = () => {
    setDisplayedNotiCount((prevCount) => prevCount + 4);
  };

  useEffect(() => {
    grabNotifications();

    const interval = setInterval(() => {
      grabNotifications();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <header className="header_main coach_header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-3">
              <Link to="/Coach/Home" className="logo_web">
                <img src="/assets/img/comman/logo.png" alt />
              </Link>
            </div>
            <div className="col-md-6"></div>
            <div className="col-md-3">
              <div className="row align-items-center">
                {/* <div className="col-auto">
                  <div className="dropdown language_drop">
                    <button
                      className
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src="/assets/img/comman/flag.svg" alt /> EN{" "}
                      <img src="/assets/img/comman/arroww_down.svg" alt />
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <a className="language_a" href="javascript:;">
                        <img src="/assets/img/comman/flag.png" alt />
                        Israel
                      </a>
                    </div>
                  </div>
                </div> */}
                <div className="col-auto pe-2">
                  <div className="dropdown language_drop">
                    <button
                      className="language_change"
                      id="languageDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={
                          i18n.language !== "he"
                            ? "/assets/img/comman/flag.svg"
                            : "/assets/img/comman/flag.png"
                        }
                        alt="Hebrew"
                      />{" "}
                      {i18n.language !== "he" ? t("EN") : t("HE")}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <a
                        className="language_change"
                        href="#"
                        onClick={() =>
                          changeLanguage(
                            i18n.language !== "he" ? "Hebrew" : "English"
                          )
                        }
                      >
                        <img
                          src={
                            i18n.language !== "he"
                              ? "/assets/img/comman/flag.png"
                              : "/assets/img/comman/flag.svg"
                          }
                          alt="English"
                        />
                        {i18n.language !== "he" ? t("HE") : t("EN")}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-auto ps-4">
                  <Link className="chat_btn" to="/Coach/Chat">
                    <img src="/assets/img/comman/message.png" alt="" />
                  </Link>
                </div>
                <div className="col-auto px-4">
                  <div className="dropdown notification_dropdown">
                    <button
                      className="notification_btn"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={() => handleReadNotification()}
                    >
                      <img src="/assets/img/comman/bell.png" alt />
                      <span>{userData?.details?.notificationCount}</span>
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="notification_head">Notification</div>
                        </div>
                        <div className="col-md-12">
                          {loading ? (
                            <div>
                              <div className="skeleton-loader notification_skeleton" />
                              <div className="skeleton-loader notification_skeleton" />
                              <div className="skeleton-loader notification_skeleton" />
                            </div>
                          ) : (
                            <>
                              {notiData
                                ?.slice(0, displayedNotiCount)
                                ?.map((i) => (
                                  <div>
                                    {i?.type === "FRIEND_REQUEST" ? (
                                      <div className="row notification_row">
                                        <div className="col-auto">
                                          <div className="notification_user">
                                            <img
                                              src={
                                                i?.sender?.profileimage ||
                                                "/assets/img/comman/user.svg"
                                              }
                                              alt
                                            />
                                          </div>
                                        </div>
                                        <div className="col">
                                          <div className="notification_content">
                                            <div class="row mb-1 align-items-center">
                                              <div class="col">
                                                <h3>{`${i?.sender?.firstname} ${i?.sender?.lastname}`}</h3>
                                              </div>
                                              <div class="col-auto">
                                                <span>
                                                  {moment(
                                                    i?.createdat
                                                  ).fromNow()}
                                                </span>
                                              </div>
                                            </div>
                                            <p> {i?.description_en}</p>
                                            <div className="row mb-2">
                                              <div className="col-auto">
                                                <a
                                                  className="FriendRequest"
                                                  href="#"
                                                  onClick={() =>
                                                    handleAcceptRejectStudent(
                                                      i?.notificationid,
                                                      true
                                                    )
                                                  } // Accept action
                                                >
                                                  Accept
                                                </a>
                                              </div>
                                              <div className="col-auto ps-0">
                                                <a
                                                  className="FriendRequest1"
                                                  href="#"
                                                  onClick={() =>
                                                    handleAcceptRejectStudent(
                                                      i?.notificationid,
                                                      false
                                                    )
                                                  } // Reject action
                                                >
                                                  Reject
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : i?.type === "NEW_MATCH" ? (
                                      <div className="row notification_row">
                                        <div className="col-auto">
                                          <div className="notification_user">
                                            <img
                                              src={
                                                i?.sender?.profileimage ||
                                                "/assets/img/comman/user.svg"
                                              }
                                              alt
                                            />
                                          </div>
                                        </div>
                                        <div className="col">
                                          <div className="notification_content">
                                            <div class="row mb-1 align-items-center">
                                              <div class="col">
                                                <h3>{`${i?.sender?.firstname} ${i?.sender?.lastname}`}</h3>
                                              </div>
                                              <div class="col-auto">
                                                <span>
                                                  {moment(
                                                    i?.createdat
                                                  ).fromNow()}
                                                </span>
                                              </div>
                                            </div>
                                            <p>{i?.description_en}</p>

                                            <Link
                                              class="viewinvitaion"
                                              to={
                                                i?.type === "NEW_MATCH"
                                                  ? `/Student/PlayerBoard`
                                                  : ""
                                              }
                                              state={{
                                                match: i?.notificationid,
                                              }}
                                              href="javascript:;"
                                            >
                                              Play Match
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    ) : i?.type === "NEW_MATCH_SCHEDULE" ? (
                                      <div className="row notification_row">
                                        <div className="col-auto">
                                          <div className="notification_user">
                                            <img
                                              src={
                                                i?.sender?.profileimage ||
                                                "/assets/img/comman/user.svg"
                                              }
                                              alt
                                            />
                                          </div>
                                        </div>
                                        <div className="col">
                                          <div className="notification_content">
                                            <div class="row mb-1 align-items-center">
                                              <div class="col">
                                                <h3>{`${i?.sender?.firstname} ${i?.sender?.lastname}`}</h3>
                                              </div>
                                              <div class="col-auto">
                                                <span>
                                                  {moment(
                                                    i?.createdat
                                                  ).fromNow()}
                                                </span>
                                              </div>
                                            </div>
                                            <p>{i?.description_en}</p>

                                            <Link
                                              class="viewinvitaion"
                                              to={
                                                i?.type === "NEW_MATCH_SCHEDULE"
                                                  ? `/Student/PlayWithMatches`
                                                  : ""
                                              }
                                              state={{
                                                match: i?.notificationid,
                                              }}
                                              href="javascript:;"
                                            >
                                              View Invitation
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="row notification_row">
                                        <div className="col-auto">
                                          <div className="notification_user">
                                            <img
                                              src={
                                                i?.sender?.profileimage ||
                                                "/assets/img/comman/user.svg"
                                              }
                                              alt
                                            />
                                          </div>
                                        </div>
                                        <div className="col">
                                          <div className="notification_content">
                                            <div class="row mb-1 align-items-center">
                                              <div class="col">
                                                <h3>{`${i?.sender?.firstname} ${i?.sender?.lastname}`}</h3>
                                              </div>
                                              <div class="col-auto">
                                                <span>
                                                  {moment(
                                                    i?.createdat
                                                  ).fromNow()}
                                                </span>
                                              </div>
                                            </div>

                                            <p>{i?.description_en}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ))}
                              <div className="row">
                                <div className="col-12 text-center">
                                  {displayedNotiCount < totalNotifications && (
                                    <a
                                      className="See_moore"
                                      href="#"
                                      onClick={loadMoreNotifications}
                                    >
                                      See More
                                    </a>
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="dropdown profile_dropdown">
                    <button
                      className="profile_header"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={
                          userData?.profileimage ||
                          "/assets/img/comman/user.svg"
                        }
                        alt
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <Link className="dropdown-item" to="/Coach/Profile">
                          My Profile
                        </Link>
                      </li>
                      <li>
                        <Link to="/Coach/Settings" className="dropdown-item">
                          Settings
                        </Link>
                      </li>
                      <li>
                        <Link to="/Coach/Terms" className="dropdown-item">
                          Terms &amp; Conditions
                        </Link>
                      </li>
                      <li>
                        <Link to="/Coach/AboutUs" className="dropdown-item">
                          About Us
                        </Link>
                      </li>
                      <li>
                        <Link to="/Coach/Privacy" className="dropdown-item">
                          Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link to="/Coach/HelpSupport" className="dropdown-item">
                          Help &amp; Support
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <a class="dropdown-item" onClick={() => logout()}>
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default CoachHomeNav;
