import React from "react";
import { Link } from "react-router-dom";

const ManagerFooterHome = () => {
  return (
    <>
      <footer className="footer_main">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="footer_about">
                <Link to="/TeamManager/Home">
                  <img
                    className="footer_logo"
                    src="/assets/img/comman/Mindleague_white.svg"
                    alt=""
                  />
                </Link>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipiscing elit aliquam
                  mauris sed ma
                </p>
                <div className="footer_social">
                  <a href="javascript:;">
                    <img src="/assets/img/comman/Facebook.svg" alt />
                  </a>
                  <a href="javascript:;">
                    <img src="/assets/img/comman/Twitter.svg" alt />
                  </a>
                  <a href="javascript:;">
                    <img src="/assets/img/comman/Instagram.svg" alt />
                  </a>
                  <a href="javascript:;">
                    <img src="/assets/img/comman/LinkedIn.svg" alt />
                  </a>
                  <a href="javascript:;">
                    <img src="/assets/img/comman/YouTube.svg" alt />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-4">
                  <div className="footer_box">
                    <h2>Team Manager</h2>
                    <div className="footermenus">
                      <Link to="/TeamManager/My_Team">◉ Team</Link>
                      <Link to="/TeamManager/My_Games">◉ Games</Link>
                      <Link to="/TeamManager/Coaches">◉ Coach</Link>
                      <Link to="/TeamManager/Certificates">◉ Certificates</Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="footer_box">
                    <h2>Customer Services</h2>
                    <div className="footermenus">
                      <Link to="/TeamManager/AboutUs">◉ About Us</Link>
                      <Link to="/TeamManager/Terms">◉ Terms & Conditions</Link>
                      <Link to="/TeamManager/Privacy">◉ Privacy Policy</Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="footer_support">
                    <div className="row mb-4 pb-3">
                      <div className="col-auto">
                        <div className="support_icom">
                          <img src="/assets/img/comman/Email.svg" alt />
                        </div>
                      </div>
                      <div className="col">
                        <div className="support_details">
                          <span>Email</span>
                          <a href="mailto:contact@mindleague.com">
                            contact@mindleague.com
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-auto">
                        <div className="support_icom">
                          <img src="/assets/img/comman/Phone.svg" alt />
                        </div>
                      </div>
                      <div className="col">
                        <div className="support_details">
                          <span>Phone Number</span>
                          <a href="tel:(345) 678 - 2345">(345) 678 - 2345</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright_container">
          <p>
            Copyright © 2025 BRIX Agency | All Rights Reserved | mindleague.com
          </p>
        </div>
      </footer>
    </>
  );
};

export default ManagerFooterHome;
