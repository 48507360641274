import React from "react";

export default function SocialLinks() {
  const socialLinks = [
    { name: "Facebook", url: "https://www.facebook.com" },
    { name: "LinkedIn", url: "https://www.linkedin.com" },
    { name: "Instagram", url: "https://www.instagram.com" },
    { name: "Twitter", url: "https://www.twitter.com" },
    { name: "YouTube", url: "https://www.youtube.com" },
  ];

  return (
    <div className="row social_part justify-content-center">
      {socialLinks.map((link, index) => (
        <div className="col-auto px-3" key={index}>
          <a href={link.url} target="_blank" rel="noopener noreferrer">
            {link.name}
          </a>
        </div>
      ))}
    </div>
  );
}
