import React, { useEffect, useState } from "react";
import CoachHomeNav from "../HomeComponents/CoachHomeNav";
import CoachHomeFooter from "../HomeComponents/CoachHomeFooter";
import { viewFeedback } from "../../HttpServices/coachHttpServices/coachHttpServices";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function FeedbackFormView() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [feedbackDetails, setFeedbackDetails] = useState({});

  const feedbackId = localStorage?.getItem("feedbackId");

  const fetchFeedbackDetails = async (id) => {
    setLoading(true);
    try {
      const response = await viewFeedback(id);
      if (response?.data?.error === false) {
        setFeedbackDetails(response.data.results);
      } else {
        setError("Unable to fetch feedback details.");
      }
    } catch (err) {
      setError(err.message || "An error occurred while fetching data.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (feedbackId) fetchFeedbackDetails(feedbackId);
  }, [feedbackId]);

  const { users, form } = feedbackDetails;

  return (
    <>
      <CoachHomeNav />
      <section className="feedback_form_details coachhead_space">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Feedback Form</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {loading ? (
                      <Skeleton width={150} />
                    ) : (
                      `${users?.firstname} ${users?.lastname}`
                    )}
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-md-12 mb-4">
              <div className="row stundent_info_box mt-5">
                <div className="col-md-12">
                  <div className="info_heading">Student Information:</div>
                </div>
                <div className="col-md-12">
                  <div className="row stundent_info_main">
                    {[
                      {
                        label: "Customer Name",
                        value: `${users?.firstname} ${users?.lastname}`,
                      },
                      {
                        label: "Mobile Number",
                        value: `${users?.countrycode} ${users?.phonenumber}`,
                      },
                      { label: "Mail Id", value: users?.email },
                      { label: "Club Name", value: users?.club },
                      {
                        label: "Date",
                        value: moment(form?.createdAt).format("MMM DD, YYYY"),
                      },
                    ].map((item, index) => (
                      <div className="col-md-3 mb-4" key={index}>
                        <div className="stundent_info_inner">
                          <span>{item.label}:</span>
                          <a href="#">
                            {loading ? <Skeleton width={120} /> : item.value}
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-2">
              <div className="row">
                <div className="col-md-12 feedbacks_table">
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th className="col-1">S.No</th>
                          <th className="col-7">Feedback Questions</th>
                          <th>Comment</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          Array(3)
                            .fill(null)
                            .map((_, index) => (
                              <tr key={index}>
                                <td>
                                  <Skeleton width={30} />
                                </td>
                                <td>
                                  <Skeleton width={200} />
                                </td>
                                <td>
                                  <Skeleton width={150} />
                                </td>
                              </tr>
                            ))
                        ) : feedbackDetails?.users?.form?.questions?.length >
                          0 ? (
                          feedbackDetails.users.form.questions.map(
                            (q, index) => (
                              <tr key={q._id}>
                                <td className="col-1">{index + 1}</td>
                                <td className="col-7">{q.question}</td>
                                <td className="col-4">
                                  <div
                                    className="accordion feedbacks_accordian"
                                    id={`accordion${index}`}
                                  >
                                    <div className="accordion-item">
                                      <h2
                                        className="accordion-header"
                                        id={`heading${index}`}
                                      >
                                        <button
                                          className="accordion-button collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target={`#collapse${index}`}
                                          aria-expanded="true"
                                          aria-controls={`collapse${index}`}
                                        >
                                          {q.comment || "No Comment"}
                                        </button>
                                      </h2>
                                      <div
                                        id={`collapse${index}`}
                                        className="accordion-collapse collapse"
                                        aria-labelledby={`heading${index}`}
                                        data-bs-parent={`#accordion${index}`}
                                      >
                                        <div className="accordion-body">
                                          {q.comment ||
                                            "No additional comments provided."}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td colSpan="3">No feedback available.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CoachHomeFooter />
    </>
  );
}
