import React, { useEffect, useRef, useState } from "react";
import { useUserAuth } from "../../Authentications/Others/AuthContext";
import { Link, useLocation } from "react-router-dom";
import {
  GetMyProfile,
  GetNotifications,
} from "../../HttpServices/teamManagerHttpServices/teamManagerHttpServices";
import moment from "moment";
import Swal from "sweetalert2";

const ManagerNavHome = () => {
  const { logout, getMyProfile, userData } = useUserAuth();
  const [notiData, setNotiData] = useState([]);
  const [displayedNotiCount, setDisplayedNotiCount] = useState(4); // Initial limit of 4
  const [totalNotifications, setTotalNotifications] = useState(0);
  const location = useLocation();
  const isMyTeamActive =
    location.pathname === "/TeamManager/My_Team" ||
    location.pathname === "/TeamManager/StudentRequest";
  useEffect(() => {
    getMyProfile();
  }, []);

  const [loading, setLoading] = useState(true); // Loading state for notifications
  useEffect(() => {
    getMyProfile();
  }, []);
  const audioPlayer = useRef(null);

  function playAudio() {
    audioPlayer.current.play();
  }
  const grabNotifications = async () => {
    try {
      setLoading(true);
      const response = await GetNotifications({
        page: 1,
        pageSize: 5,
      });
      setNotiData(response?.data?.results?.notifications);
      setTotalNotifications(response?.data?.results?.notifications.length);
      setLoading(false);
    } catch (error) {
      console.log(error?.message);
      setLoading(false);
    }
  };

  const loadMoreNotifications = () => {
    setDisplayedNotiCount((prevCount) => prevCount + 4); // Load 4 more notifications
  };

  useEffect(() => {
    // Fetch notifications when the component mounts
    grabNotifications();

    // Set up polling to refresh notifications every 60 seconds
    const interval = setInterval(() => {
      grabNotifications();
    }, 60000); // 60000ms = 60 seconds

    // Cleanup the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <header className="header_main">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-3">
              <Link to="/TeamManager/Home" className="logo_web">
                <img src="/assets/img/comman/logo.png" alt />
              </Link>
            </div>
            <div className="col-md-6">
              <div className="header_menus text-center">
                <Link
                  to="/TeamManager/Home"
                  className={
                    location.pathname === "/TeamManager/Home" ? "active" : ""
                  }
                >
                  Home
                </Link>
                <Link
                  to="/TeamManager/My_Team"
                  className={isMyTeamActive ? "active" : ""}
                >
                  Team
                </Link>
                <Link
                  to="/TeamManager/My-Games"
                  className={
                    location.pathname === "/TeamManager/My-Games"
                      ? "active"
                      : ""
                  }
                >
                  Games
                </Link>
                <Link
                  to="/TeamManager/Coaches"
                  className={
                    location.pathname === "/TeamManager/Coaches" ? "active" : ""
                  }
                >
                  Coach
                </Link>
                <Link
                  to="/TeamManager/Certificates"
                  className={
                    location.pathname === "/TeamManager/Certificates"
                      ? "active"
                      : ""
                  }
                >
                  Certificates
                </Link>
              </div>
            </div>
            <div className="col-md-3">
              <div className="row align-items-center justify-content-end ">
                <div className="col-auto">
                  <div className="dropdown language_drop">
                    <button
                      className
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src="/assets/img/comman/flag.svg" alt /> EN{" "}
                      <img src="/assets/img/comman/arroww_down.svg" alt />
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <a className="language_a" href="javascript:;">
                        <img src="/assets/img/comman/flag.png" alt />
                        Israel
                      </a>
                    </div>
                  </div>
                </div>
                {/* <div className="col-auto">
                  <a className="chat_btn" href="chat.html">
                    <img src="/assets/img/comman/message.png" alt />
                  </a>
                </div> */}
                <div className="col-auto px-4">
                  <div className="dropdown notification_dropdown">
                    <button
                      className="notification_btn"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src="/assets/img/comman/bell.png" alt="" />
                      <span>{notiData?.length}</span>
                    </button>
                    <div
                      className="dropdown-menu dropdown-menu-right"
                      aria-labelledby="dropdownMenuButton1"
                      style={{ maxHeight: "400px", overflowY: "auto" }} // Scrollable area
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="notification_head">Notifications</div>
                        </div>
                        <div className="col-md-12">
                          {loading ? (
                            // Skeleton loader when loading notifications
                            <div>
                              <div className="skeleton-loader notification_skeleton" />
                              <div className="skeleton-loader notification_skeleton" />
                              <div className="skeleton-loader notification_skeleton" />
                            </div>
                          ) : (
                            <>
                              {notiData
                                ?.slice(0, displayedNotiCount)
                                ?.map((i) => (
                                  <div>
                                    {i?.type === "NEW_MATCH" ? (
                                      <div className="row notification_row">
                                        <div className="col-auto">
                                          <div className="notification_user">
                                            <img
                                              src={
                                                i?.sender?.profileimage ||
                                                "/assets/img/comman/user.svg"
                                              }
                                              alt
                                            />
                                          </div>
                                        </div>
                                        <div className="col">
                                          <div className="notification_content">
                                            <div class="row mb-1 align-items-center">
                                              <div class="col">
                                                <h3>{`${i?.sender?.firstname} ${
                                                  i?.sender?.lastname !=
                                                  "undefined"
                                                    ? i?.sender?.lastname
                                                    : ""
                                                }`}</h3>
                                              </div>
                                              <div class="col-auto">
                                                <span>
                                                  {moment(
                                                    i?.createdAt
                                                  ).fromNow()}
                                                </span>
                                              </div>
                                            </div>
                                            <p>{i?.description_en}</p>

                                            <Link
                                              class="viewinvitaion"
                                              to={
                                                i?.type === "NEW_MATCH"
                                                  ? `/Student/PlayerBoard`
                                                  : ""
                                              }
                                              state={{
                                                match: i?.notificationid,
                                              }}
                                              href="javascript:;"
                                            >
                                              Play Match
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    ) : i?.type === "NEW_MATCH_SCHEDULE" ? (
                                      <div className="row notification_row">
                                        <div className="col-auto">
                                          <div className="notification_user">
                                            <img
                                              src={
                                                i?.sender?.profileimage ||
                                                "/assets/img/comman/user.svg"
                                              }
                                              alt
                                            />
                                          </div>
                                        </div>
                                        <div className="col">
                                          <div className="notification_content">
                                            <div class="row mb-1 align-items-center">
                                              <div class="col">
                                                <h3>{`${i?.sender?.firstname} ${
                                                  i?.sender?.lastname !=
                                                  "undefined"
                                                    ? i?.sender?.lastname
                                                    : ""
                                                }`}</h3>
                                              </div>
                                              <div class="col-auto">
                                                <span>
                                                  {moment(
                                                    i?.createdat
                                                  ).fromNow()}
                                                </span>
                                              </div>
                                            </div>
                                            <p>{i?.description_en}</p>

                                            <Link
                                              class="viewinvitaion"
                                              to={
                                                i?.type === "NEW_MATCH_SCHEDULE"
                                                  ? `/Student/PlayWithMatches`
                                                  : ""
                                              }
                                              state={{
                                                match: i?.notificationid,
                                              }}
                                              href="javascript:;"
                                            >
                                              View Invitation
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="row notification_row">
                                        <div className="col-auto">
                                          <div className="notification_user">
                                            <img
                                              src={
                                                i?.sender?.profileimage ||
                                                "/assets/img/comman/user.svg"
                                              }
                                              alt
                                            />
                                          </div>
                                        </div>
                                        <div className="col">
                                          <div className="notification_content">
                                            <div class="row mb-1 align-items-center">
                                              <div class="col">
                                                <h3>{`${i?.sender?.firstname} ${
                                                  i?.sender?.lastname !=
                                                  "undefined"
                                                    ? i?.sender?.lastname
                                                    : ""
                                                }`}</h3>
                                              </div>
                                              <div class="col-auto">
                                                <span>
                                                  {/* {moment(
                                                    i?.createdat
                                                  ).fromNow()} */}
                                                </span>
                                              </div>
                                            </div>

                                            <p>{i?.description_en}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ))}
                              <div className="row">
                                <div className="col-12 text-center">
                                  {displayedNotiCount < totalNotifications && (
                                    <a
                                      className="See_moore"
                                      href="#"
                                      onClick={loadMoreNotifications}
                                    >
                                      See More
                                    </a>
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                          <div className="row">
                            <div className="col-12 text-center">
                              {displayedNotiCount < totalNotifications && (
                                <a
                                  className="See_moore"
                                  href="#"
                                  onClick={loadMoreNotifications}
                                >
                                  See More
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-auto">
                  <div class="dropdown profile_dropdown">
                    <button
                      class="profile_header"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={
                          userData?.profileimage ||
                          "/assets/img/comman/user.svg"
                        }
                        alt=""
                      />
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <Link to="/TeamManager/Profile" class="dropdown-item">
                          My Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/TeamManager/Alumni-Club-Memebers"
                          class="dropdown-item"
                        >
                          Alumni Management
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/TeamManager/BackupManager"
                          class="dropdown-item"
                        >
                          Backup Team Manager Management
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/TeamManager/PushNotification"
                          class="dropdown-item"
                        >
                          Push Notification
                        </Link>
                      </li>
                      <li>
                        <Link to="/TeamManager/Settings" class="dropdown-item">
                          Settings
                        </Link>
                      </li>
                      <li>
                        <Link to="/TeamManager/AboutUs" class="dropdown-item">
                          About Us
                        </Link>
                      </li>
                      <li>
                        <Link to="/TeamManager/Terms" class="dropdown-item">
                          Terms & Conditions
                        </Link>
                      </li>
                      <li>
                        <Link to="/TeamManager/Privacy" class="dropdown-item">
                          Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/TeamManager/HelpSupport"
                          class="dropdown-item"
                        >
                          Help & Support
                        </Link>
                      </li>
                      <li>
                        <Link onClick={() => logout()} class="dropdown-item">
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default ManagerNavHome;
