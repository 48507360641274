import React from "react";
import { Link } from "react-router-dom";
import SocialLinks from "./SocialLinks";
import Copyright from "./Copyright";

const CoachHomeFooter = () => {
  return (
    <>
      <footer className="student_footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="st_footer_about">
                <a href="javascript:;">
                  <img src="/assets/img/comman/logo.png" alt />
                </a>
                <p>
                  Join us for an engaging session filled with strategic
                  discussions, challenging matches, and insightful analysis.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6">
                  <div className="st_footer_menu">
                    <h2>Customer Services</h2>
                    <div className="stfooter_links">
                      <Link to="/Coach/AboutUs">About Us</Link>
                      <Link to="/Coach/Terms">Terms &amp; Conditions</Link>
                      <Link to="/Coach/Privacy">Privacy Policy</Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="st_support">
                    <div className="row mb-4">
                      <div className="col-auto">
                        <div className="st_support_icon">
                          <img src="/assets/img/comman/Email__st.svg" alt />
                        </div>
                      </div>
                      <div className="col ps-0">
                        <div className="st_support_details">
                          <span>Email:</span>
                          <a href="mailto:contact@mindleague.com">
                            contact@mindleague.com
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-auto">
                        <div className="st_support_icon">
                          <img src="/assets/img/comman/Phone_st.svg" alt />
                        </div>
                      </div>
                      <div className="col ps-0">
                        <div className="st_support_details">
                          <span>Phone:</span>
                          <a href="tel:(345) 678 - 2345">(345) 678 - 2345</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <SocialLinks />
          <Copyright />
        </div>
      </footer>
    </>
  );
};

export default CoachHomeFooter;
